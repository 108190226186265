<template>
  <b-modal
    size="xl"
    id="modal-agentur-duplicates-confirmation"
    scrollable
    centered
    :title="$t(modalTitelLocalizationString)"
    @hide="onHide($event)"
  >
    {{ $t('KUNDEN.DUBLETTEN_CONFIRMATION_MERGE_MSG') }}
    <AgenturDuplicatesCompareTable
      v-if="confirmationModalPairAndTrigger"
      :agentur1="confirmationModalPairAndTrigger.agentur1"
      :agentur2="confirmationModalPairAndTrigger.agentur2"
    />

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="success"
        @click="
          ok();
          onModalOkay();
        "
      >
        {{ $t('COMMON.JA') }}
      </b-button>
      <b-button variant="danger" @click="cancel()">{{ $t('COMMON.NEIN') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { SAVE_AGENTUR_DUPLICATES_MERGE_STATE } from '@/core/agenturen/stores/agenturDuplicates.module';
import AgenturDuplicatesCompareTable from '@/components/agenturen/agentur-duplicates-compare-table.vue';
export default {
  components: {
    AgenturDuplicatesCompareTable,
  },
  watch: {
    confirmationModalPairAndTrigger(confirmationModalPairAndTrigger) {
      if (confirmationModalPairAndTrigger) {
        this.$bvModal.show('modal-agentur-duplicates-confirmation');
      }
    },
  },

  methods: {
    onModalOkay() {
      this.$store
        .dispatch(SAVE_AGENTUR_DUPLICATES_MERGE_STATE, {
          agentur1Id: this.confirmationModalPairAndTrigger.agentur1.id,
          agentur2Id: this.confirmationModalPairAndTrigger.agentur2.id,
          trigger: this.confirmationModalPairAndTrigger.trigger,
        })
        .then(() => {
          this.$bvModal.hide('modal-agentur-duplicates-confirmation');
        });
    },
    onHide($event) {
      if ($event.trigger !== 'ok') {
        this.toast('Aktion abgebrochen', { variant: 'secondary' });
      }
    },
  },
  computed: {
    ...mapState({
      confirmationModalPairAndTrigger: state => state.agenturDuplicates.confirmationModalPairAndTrigger,
    }),
    modalTitelLocalizationString() {
      return this.confirmationModalPairAndTrigger
        ? `AGENTUREN.DUBLETTEN_CONFIRMATION_${
            this.confirmationModalPairAndTrigger.trigger === 0
              ? 'MERGE'
              : this.confirmationModalPairAndTrigger.trigger === 1
              ? 'CANCEL'
              : 'UNMERGE'
          }_TITLE`
        : '';
    },
  },
};
</script>
